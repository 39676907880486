<template>
	<div class="Department">
		
		<h3>
			<em>部门列表</em>
			<span>
				<el-button type="primary">添加部门</el-button>
			</span>
		</h3>

		<div class="List">
		<DepartmentItem v-if="Departments.length > 0" :departments="Departments"></DepartmentItem>
		</div>

	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Radio} from 'element-ui'
	import DepartmentItem from '@/components/organization/DepartmentItem.vue'
	export default {
	  name: 'Members',
	  props: {
	  },
	  data() {
	      return {
			  Departments:[
				{Id:'a1',Name:'技术部',MemberCount:3,Children:[]},
				{Id:'a2',Name:'设计部',MemberCount:12,Children:[]},
				{Id:'a3',Name:'研发部',MemberCount:12,Children:[{Id:'a4',Name:'研发1部',MemberCount:113,Children:[]},{Id:'a5',Name:'研发2部',MemberCount:62,Children:[]}]}
			  ]
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-radio':Radio,
		DepartmentItem
	  },
	  methods:{
	  }
	}
</script>

<style scoped>
.Department{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 30px;
}
.Department h3{
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba(0,0,0,0.1);
	padding-bottom: 20px;
}
.Department h3 em{
	flex: 1;
}
.Department .List{
	border: 1px solid rgba(0,0,0,0.1);
	border-top-width: 0px;
}
</style>
